/**
 * The main js file for the theme
 *
 * This is the entry point where all modules are imported and compiled.
 *
 * @package Embark
 * @since   1.0.0
 */

/**
 * IE Polyfills
 *
 * @since 1.0.0
 */
import './modules/theme/polyfills';

/**
 * Font-loading with 'fontfaceobserver'.
 *
 * @since 1.0.0
 */
import './modules/theme/webfonts';

/**
 * Lazy-load images with 'vanilla-lazyload',
 * and initialize picturefill after they've loaded.
 *
 * @since 1.0.0
 */
import './modules/theme/lazyload';

/**
 * Initialize the 'svgxuse' polyfill.
 * Optionally, render all svgs in <img> tags as actual svgs.
 *
 * @since 1.0.0
 */
import './modules/theme/svgs';

/**
 * Initialize object-fit/position polyfill.
 *
 * @since 1.0.0
 */
import './modules/theme/object-fit';

/**
 * Add classes to image and video thumbnail links, and setup
 * click events to open the image/video in a Colorbox modal.
 *
 * @since 1.0.0
 */
import './modules/theme/lightboxes';

/**
 * Make file inputs and select elements easier to style.
 *
 * @since 1.0.0
 */
import './modules/theme/forms';

/**
 * Toggle 'scrolled' class on sticky header.
 *
 * @since 1.0.0
 */
import './modules/theme/sticky-header';

/**
 * Toggle 'scrolled' class on sticky header.
 *
 * @since 1.0.0
 */
import './modules/theme/main-menu';

/**
 * Search Box Toggle
 *
 * @since 1.0.0
 */
import './modules/theme/search';

/**
 * Category Card Toggle
 *
 * @since 1.0.0
 */
import './modules/theme/category-card';

/**
 * Leadership Card Toggle
 *
 * @since 1.0.0
 */
import './modules/theme/leadership-card';

/**
 * Accordions
 *
 * @since 1.0.0
 */
import './modules/theme/accordions';

/**
 * Tabbed Content
 *
 * @since 1.0.0
 */
import './modules/theme/tabbed-content';

/**
 * Countup Animation
 *
 * @since 1.0.0
 */
import './modules/theme/countup';

/**
 * Homepage Masthead
 *
 * @since 1.0.0
 */
import './modules/theme/hp-masthead';

/**
 * Resource Filtering
 *
 * @since 1.0.0
 */
import './modules/theme/resource-filter';

/**
 * Blog functions.
 *
 * @since 1.0.0
 */
import './modules/theme/blog';
