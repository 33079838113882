/**
 * Category Card Toggle
 *
 * @package Embark
 * @since   1.0.0
 */

import $ from 'jquery';

import * as helpers from '../global/helpers';

function catCardToggle() {
	const width = $(window).width();

	if (width > 580) {
		$('.category-card').hover(
			function () {
				$(this).delay(500).addClass('open');
				$(this).find('.category-card__list').delay(500).fadeIn(500);
			},
			function () {
				$(this).removeClass('open');
				$(this).find('.category-card__list').fadeOut(0);
			},
		);
	}
}


window.addEventListener('load', catCardToggle);
window.addEventListener('resize', helpers.debounce(catCardToggle, 100, true));
