/**
 * Object fit polyfill for easier image sizing
 *
 * @package Embark
 * @since   1.0.0
 */

/**
 * Object Fit Images.
 *
 * Polyfill object-fit/object-position on <img>: IE9, IE10, IE11, Edge, Safari, ...
 *
 * @since 1.0.0
 *
 * @see https://www.npmjs.com/package/object-fit-images
 */
import objectFitImages from 'object-fit-images';

window.addEventListener( 'load', (e) => {
	objectFitImages();
} );


/**
 * Object Fit Images.
 *
 * Polyfill object-fit/object-position on <img>: IE9, IE10, IE11, Edge, Safari, ...
 *
 * @since 1.0.0
 *
 * @see https://www.npmjs.com/package/object-fit-videos
 */
import objectFitVideos from 'object-fit-videos';

window.addEventListener( 'load', (e) => {
	objectFitVideos();
} );
