/**
 * Modals ( also known as lightboxes, colorboxes, etc )
 *
 * @package Embark
 * @since   1.0.0
 */

/**
 * Imports jQuery.
 *
 * @see http://api.jquery.com/
 *
 * @since 1.0.0
 */
import $ from 'jquery';

/**
 * Imports jQuery Colorbox.
 *
 * A customizable lightbox plugin for jQuery.
 *
 * @see https://www.npmjs.com/package/jquery-colorbox
 * @see http://www.jacklmoore.com/colorbox/
 *
 * @since 1.0.0
 */
import colorbox from 'jquery-colorbox';

/**
 * Common variables used throughout the project.
 *
 * @since 1.0.0
 */
import { breakpoints } from './variables';

/**
 * Add a classname to the set of elements, given that their direct
 * child is an img or picture element.
 *
 * @since 1.0.0
 *
 * @param {jQuery} $els      jQuery object of elements to loop through.
 * @param {String} className Class to add to elements if they have an img or picture as a direct child.
 */
function addImageLinkClass( $els, className ) {
	let $this;
	$els.each(
		function() {
			$this = $( this );
			if ( $this.find( '> img, > picture' ).length ) {
				$this.addClass( className );
			}
		}
	);
}

/**
 * Launches links to valid image formats into a lightbox ( via ColorBox ) instead of opening within window.
 *
 * @see http://www.jacklmoore.com/colorbox/
 *
 * @since  1.0.0
 */
function initImageLightBox() {
	const imageLinks = '[href*=".jpg"],[href*=".gif"],[href*=".png"]';

	addImageLinkClass( $( imageLinks ), 'enlarge' );

	$( document ).on(
		'click', imageLinks, function( e ) {
			e.preventDefault();
			$.colorbox(
				{
					"href" : $( this ).attr( 'href' ),
					"close" : "",
					"width" : "auto",
					"maxWidth" : "90%",
					"maxHeight" : "90%",
					"height" : "auto",
					"opacity" : 0.7,
					"fixed" : true
				}
			);
		}
	);
};

/**
 * Display YouTube, Vimeo or Wistia videos into a lightbox ( via ColorBox )
 * if the viewport is above a certain size and the device is not iOS.
 *
 * @see http://www.jacklmoore.com/colorbox/
 *
 * @since  1.0.0
 */
function initVideoLightbox() {
	const videoLinks = '[href*="youtube.com/watch"],[href*="youtu.be"],[href*="vimeo.com"],[href*="wistia.com"],[href*=".mp4"]';

	addImageLinkClass( $( videoLinks ), 'play-btn' );

	$( document ).on(
		'click', videoLinks, function( e ) {
			const width = $( window ).width();
			// Check if this is an iOS Device.
			const isiPad = navigator.userAgent.match( /iPad/i ) !== null,
			isiPhone     = navigator.userAgent.match( /iPhone/i ) !== null;

			if ( isiPad === true || isiPhone === true ) {
				return;
			}

			if ( width > breakpoints.xs.min ) {
				e.preventDefault();

				const $this = $( this );
				let href    = $this.attr( 'href' );

				// Return if the current YouTube link is to a profile.
				if ( $this.parent().attr( 'data-yt' ) ) {
					return;
				}

				// Lightbox Widths and Heights.
				let cbWidth  = 853;
				let cbHeight = 480;

				if ( width > breakpoints.lg.min ) {
					cbWidth  = 980;
					cbHeight = 551;
				} else if ( width <= breakpoints.xs.max ) {
					cbWidth = 500;
					bHeight = 281;
				} else if ( width <= breakpoints.sm.max ) {
					cbWidth  = 650;
					cbHeight = 365;
				}

				let hrefParts;

				// YouTube - Full URL.
				if ( href.indexOf( '/watch' ) >= 0 ) {
					hrefParts = href.split( '?' );

					const queryStringParts       = hrefParts[1].split( '&' );
					const queryStringPartsLength = queryStringParts.length;
					let videoID;

					for ( let i = 0; i < queryStringPartsLength; i++ ) {
						const tmp = queryStringParts[i].split( '=' );
						// Iterate through the query string parts and set videoID if 'v' is found.
						if ( tmp[0] === 'v' ) {
							videoID = tmp[1];
						}
					}

					href = 'https://www.youtube.com/embed/' + videoID + '?autoplay=1&hd=1&rel=0';
				}

				// YouTube - Short URL.
				if ( href.indexOf( 'youtu.be' ) >= 0 ) {
					hrefParts = href.split( '/' );
					href      = 'https://www.youtube.com/embed/' + hrefParts[hrefParts.length - 1] + '?autoplay=1&hd=1&rel=0';
				}

				// Vimeo.
				if ( href.indexOf( 'vimeo.com' ) >= 0 ) {
					// Only update the href variable if the URL is not for the Vimeo embed.
					if ( href.indexOf( 'player.vimeo.com' ) === 0 ) {
						hrefParts = href.split( '/' );
						href      = 'http://player.vimeo.com/video/' + hrefParts[hrefParts.length - 1] + '?autoplay=1';
					}
				}

				// Wistia.
				if ( href.indexOf( 'wistia.com' ) >= 0 ) {
					// Only update the href variable if the URL is not for the Wistia embed.
					if ( href.indexOf( 'fast.wistia.net' ) === 0 ) {
						hrefParts = href.split( '/' );
						href      = 'http://fast.wistia.net/embed/iframe/' + hrefParts[hrefParts.length - 1] + '?autoplay=1';
					}
				}

				$.colorbox(
					{
						"href": href,
						"close": "",
						"iframe": 1,
						"innerHeight": cbHeight,
						"innerWidth": cbWidth,
						// "initialHeight": 200,
						// "initialWidth": 200,
						"opacity": 0.7,
						"fixed" : true
					}
				);
			}
		}
	);
};

document.addEventListener( 'DOMContentLoaded', ( e ) => {
	initImageLightBox();
	initVideoLightbox();
} );

/**
 * Open leadership bios in lightbox
 *
 * @see http://www.jacklmoore.com/colorbox/
 *
 * @since  1.0.0
 */
function leadershipLightboxes() {
	$( '.block-person__bio' ).on( 'click', function(e) {
		e.preventDefault();
		const mainwidth = $( window ).width();

		if (mainwidth > 769) {
			$.colorbox( {
				"href" : $( this ).attr( 'href' ),
				"close" : "",
				"width" : "75%",
				"maxWidth" : "90%",
				"maxHeight" : "90%",
				"height" : "50%",
				"opacity" : 0.7,
				"fixed" : true
			} );
		} else {
			$.colorbox( {
				"href" : $( this ).attr( 'href' ),
				"close" : "",
				"width" : "90%",
				"maxWidth" : "90%",
				"maxHeight" : "90%",
				"height" : "auto",
				"opacity" : 0.7,
				"top" : "30px",
			} );
		}
	} );
}


window.addEventListener( 'load', leadershipLightboxes );
