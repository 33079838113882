/**
 * Search box toggle functionality
 *
 * @package Embark
 * @since   1.0.0
 */

import $ from 'jquery';
import 'jquery-ui';


function searchBox() {
	$( '.search-trigger' ).click(
		function () {
			$( '.site-search' ).toggle( "slide" );
			$( "#js-search-input-desktop" ).focus();
		}
	)

	$( '.search-close' ).click(
		function () {
			$( '.site-search' ).toggle( "slide" );
		}
	)
}

function searchValidate() {
	$( ".search-button" ).click(
		function(){
			$( ".error" ).hide();
			var hasError  = false;
			var searchVal = $( "#js-search-input-desktop" ).val();
			if (searchVal == '') {
				$( "#js-search-input-desktop" ).after( '<span class="error">Please enter a search term.</span>' );
				hasError = true;
						$( "#js-search-input-desktop" ).focus();
			}
			if (hasError == true) {
					return false;
			}
		}
	);
	$( ".search-submit" ).click(
		function(){
			$( ".error" ).hide();
			var hasError  = false;
			var searchVal = $( ".search-field" ).val();
			if (searchVal == '') {
				$( ".search-field" ).after( '<span class="error" style="position: absolute;">Please enter a search term.</span>' );
				hasError = true;
						$( ".search-field" ).focus();
			}
			if (hasError == true) {
					return false;
			}
		}
	);
}


window.addEventListener( 'load', searchBox );
window.addEventListener( 'load', searchValidate );
