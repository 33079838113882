/**
 * Main menu functionality for responsive behaviors and dropdowns.
 *
 * @package  embark
 * @since    1.0.0
 */

/**
 * Import dependencies.
 */
import $ from 'jquery';
import * as helpers from '../global/helpers';
import {els, breakpoints} from './variables';

/**
 * Menu items that have submenus.
 *
 * @type {NodeList}
 */
const menuItemsWithChildren = els.mainMenu ? els.mainMenu.querySelectorAll( '.menu-item-has-children' ) : null;

/**
 * All submenus.
 *
 * @type {NodeList}
 */
const submenus = els.mainMenu ? els.mainMenu.querySelectorAll( '.sub-menu' ) : null;

/**
 * Screensize that mobile menu is enabled/disabled
 *
 * @type {Int}
 */
const menuBreakPoint = breakpoints.md.min;

/**
 * Class used for menu item triggers.
 *
 * @type {String}
 */
const menuItemTriggerClass = 'menu-item__trigger';

/**
 * Class added to <html> when menu is open.
 *
 * @type {String}
 */
const menuOpenHtmlClass = 'html--menu-open';

/**
 * Class added to navicon when menu is open.
 *
 * @type {String}
 */
const menuOpenNaviconClass = 'navicon__trigger--open';

/**
 * Maybe add menu item triggers, if they haven't already been added.
 */
function maybeAddMenuItemTriggers() {
	if ( document.querySelectorAll( '.' + menuItemTriggerClass ).length === 0 ) {
		helpers.forEach( menuItemsWithChildren, ( i, item ) => {
			const trigger     = document.createElement( 'I' );
			trigger.className = menuItemTriggerClass;
			item.appendChild( trigger );
		} );
	}
}

/**
 * Toggle mobile submenus on click.
 *
 * @param  {Event} e
 */
function handleMenuItemTriggerClick( e ) {
	/**
	 * Prevents further propagation of the current event in the capturing and bubbling phases.
	 */
	e.stopPropagation();

	/**
	 * Check if the event target contains the menu item trigger class.
	 */
	if ( e.target.classList.contains( menuItemTriggerClass ) ) {
		/**
		 * Prevent the click from linking to a new page.
		 */
		e.preventDefault();

		$( e.target.previousElementSibling ).slideToggle();
		$( e.target ).closest( '.menu-item' ).toggleClass( 'open' );
	}
}

/**
 * Maybe close opened submenus.
 */
function maybeCloseSubmenus() {
	const openSubmenus = els.mainMenu.querySelectorAll( '.sub-menu.open' );
	if ( openSubmenus && window.innerWidth >= menuBreakPoint ) {
		helpers.forEach( openSubmenus, ( i, submenu ) => {
			submenu.style.display = '';
			submenu.classList.remove( 'open' );
		} );
	}
}

/**
 * Check if current submenu is fully within the viewport. If not, add a class so that it can be styled accordingly.
 *
 * @param  {Event} e
 */
function checkSubmenuPosition( e ) {
	const submenu = this.querySelector( '.sub-menu' );
	if ( ! helpers.isElementInViewport( submenu ) && window.innerWidth >= menuBreakPoint ) {
		this.classList.add( 'submenu-offscreen' );
	}
}

/**
 * Add class to the first .current-menu-ancestor to avoid multiple being highlighted.
 *
 * @param  {Event} e
 */
function handleMultipleMenuAncestors( e ) {
	const ancestorItems = document.querySelectorAll( '.main-menu > .current-page-ancestor' );
	if ( ancestorItems.length > 0 ) {
		ancestorItems[0].classList.add( 'current-menu-ancestor--primary' );
	}
}

/**
 * Update the menu 'top' style attribute based on screensize.
 */
function updateMenuTopOffset() {
	if ( els.headerPhone.length ) {
		els.navContainer.style.top = window.innerWidth < menuBreakPoint ? els.header.clientHeight - els.headerPhone[0].clientHeight + 'px' : '';
	} else {
		els.navContainer.style.top = window.innerWidth < menuBreakPoint ? els.header.clientHeight + 'px' : '';
	}
}

/**
 * Open the mobile menu.
 */
function openMobileMenu() {
	els.html.classList.add( menuOpenHtmlClass );
	els.navicon.firstElementChild.classList.add( menuOpenNaviconClass );
	$( '.search-form' ).prependTo( ".main-menu" );
	if ( $( '.current-menu-ancestor--primary' ).closest( '.menu-item' ).hasClass( 'open' ) ) {
		// do nothing.
	} else {
		$( '.current-menu-ancestor--primary' ).closest( '.menu-item' ).toggleClass( 'open' );
	}
}

/**
 * Close the mobile menu.
 */
function closeMobileMenu() {
	$( '.search-form' ).appendTo( ".site-search" );
	els.html.classList.remove( menuOpenHtmlClass );
	els.navicon.firstElementChild.classList.remove( menuOpenNaviconClass );
}

/**
 * Toggle the mobile menu.
 */
function toggleMobileMenu() {
	if ( els.html.classList.contains( menuOpenHtmlClass ) ) {
		closeMobileMenu();
	} else {
		openMobileMenu();
	}
}

/**
 * Close the mobile menu when you click off of it.
 *
 * @param  {Event} e
 */
function handleMenuState( e ) {
	/**
	 * If the menu is open, and the event target isn't the nav or a child of the nav, then exit quickly.
	 */
	if ( els.html.classList.contains( menuOpenHtmlClass ) && ( e.target === els.navContainer || $( e.target ).closest( els.navContainer ).length > 0 ) ) {
		return;
	}

	/**
	 * If the menu isn't open and the event target is the navicon or any of its children, then add the menu open the menu.
	 */
	if ( e.target === els.navicon || $( e.target ).closest( '.navicon__trigger' ).length > 0 ) {
		maybeAddMenuItemTriggers();
		toggleMobileMenu();
	} /**
	 * Otherwise, close the menu.
	 */
	else {
		// closeMobileMenu();.
	}
}

/**
 * Update mobile menu top offset and close opened submenus depending on screensize.
 *
 * @param  {Event} e
 */
function handleMenuOnResize( e ) {
	maybeCloseSubmenus();

	if ( window.innerWidth >= menuBreakPoint ) {
		closeMobileMenu();
	}
}

/**
 * Initialze menu.
 *
 * @param  {Event} e [description]
 */
function initMainMenu( e ) {
	handleMultipleMenuAncestors( e );

	/**
	 * Add event listeners for menu items with submenus.
	 */
	if ( menuItemsWithChildren ) {
		helpers.forEach( menuItemsWithChildren, ( i, item ) => {
			item.addEventListener( 'mouseenter', checkSubmenuPosition );
			item.addEventListener( 'click', handleMenuItemTriggerClick );
		} );
	}
}
$( window ).bind( 'orientationchange', function( event ) { // for iPad rotation.
	if ( window.innerWidth >= 1024 ) {
		closeMobileMenu();
	}

});

/**
 * Make sure current page has active state on menu (for anchor links).
 */
function currentPage() {
	var currentLocation = window.location.href;
	$( '.main-menu a[href="' + currentLocation + '"]' ).parent().addClass( 'current_page_item' );
}

/**
 * Close mobile menu if anchor link on same page is clicked.
 */
function anchorCloseMenu() {
	var currentLocation = window.location.href;
	if ( window.innerWidth < 1024 ) {
		$( '.main-menu a' ).click( function() {
			var linkhref = $( this ).attr( 'href' );
			if ( linkhref.indexOf( window.location.href ) > -1 ) {
				closeMobileMenu();
			}
		} );
	}
}

window.addEventListener( 'DOMContentLoaded', initMainMenu );
window.addEventListener( 'resize', helpers.debounce( handleMenuOnResize, 100, false ) );
document.addEventListener( 'click', handleMenuState );
window.addEventListener( 'DOMContentLoaded', currentPage );
window.addEventListener( 'DOMContentLoaded', anchorCloseMenu );
