/**
 * Accordion Toggling
 *
 * @package Embark
 * @since   1.0.0
 */

import $ from 'jquery';

import * as helpers from '../global/helpers';

function accordionToggle() {
	$( ".accordion__header" ).click(
		function () {
			$( this ).next( '.accordion__body' ).slideToggle();

			if ( $( this ).hasClass( 'expanded' ) ) {
				$( this ).removeClass( 'expanded' );
				$( this ).siblings( '.accordion__body' ).removeClass( 'expanded' );
			} else {
				$( this ).addClass( 'expanded' );
				$( this ).siblings( '.accordion__body' ).addClass( 'expanded' );
			}
		}
	);

	$( ".block-resource-filter__header" ).click(
		function () {
			$( this ).next( '.block-resource-filter__body' ).slideToggle( 200 );

			if ( $( this ).hasClass( 'expanded' ) ) {
				$( this ).removeClass( 'expanded' );
				$( this ).siblings( '.block-resource-filter__body' ).removeClass( 'expanded' );
			} else {
				$( this ).addClass( 'expanded' );
				$( this ).siblings( '.block-resource-filter__body' ).addClass( 'expanded' );
			}
		}
	);

	$( ".block-product-subcategory__header" ).click(
		function () {
			$( this ).next( '.block-product-subcategory__body' ).slideToggle( 200 );

			if ( $( this ).hasClass( 'expanded' ) ) {
				$( this ).removeClass( 'expanded' );
				$( this ).siblings( '.block-product-subcategory_body' ).removeClass( 'expanded' );
			} else {
				$( this ).addClass( 'expanded' );
				$( this ).siblings( '.block-product-subcategory__body' ).addClass( 'expanded' );
			}
		}
	);
}

function fire_once(fn, context) {
	var result;

	return function() {
		if (fn) {
			result = fn.apply( context || this, arguments );
			fn     = null;
		}

		return result;
	};
}

function accordionToggleMobile() {
	// Collapse on mobile.
	const width = $( window ).width();

	if ( width <= 768 ) {
		if ( $( '.block-resource-filter__header' ).hasClass( 'expanded' ) ) {

		} else {
			$( '.block-resource-filter__header' ).addClass( 'expanded' );
			$( '.block-resource-filter__header' ).siblings( '.block-resource-filter__body' ).addClass( 'expanded' );
		}
	}
}

var accordionToggleOnce = fire_once(
	function() {
		const width = $( window ).width();

		if ( width <= 768 ) {
			if ( $( '.block-resource-filter__header' ).hasClass( 'expanded' ) ) {

			} else {
				$( '.block-resource-filter__header' ).addClass( 'expanded' );
				$( '.block-resource-filter__header' ).siblings( '.block-resource-filter__body' ).addClass( 'expanded' );
			}
		}
	}
);

window.addEventListener( 'load', accordionToggle );
window.addEventListener( 'load', accordionToggleOnce );
window.addEventListener( 'resize', helpers.debounce( accordionToggleMobile, 100, false ) );
