/**
 * Countup block animation.
 *
 * @package Embark
 * @since   1.0.0
 */

import $ from 'jquery';
import 'jquery-visible';
import * as helpers from '../global/helpers';


function countUp() {
	if ( $( '.block-countup' ).visible( true ) ) {
		countUpAnimation();
	}
}

function countUpAnimation() {
	$( '.block-countup__value' ).each(
		function(i, that) {
			setTimeout(
				function(){
					var $this = $( that ),
					countTo   = $this.attr( 'data-count' );

					$( { countNum: $this.text()} ).animate(
						{
							countNum: countTo
						},
						{
							duration: 500,
							easing:'linear',
							step: function() {
								$this.text( Math.floor( this.countNum ) );
							},
							complete: function() {
								$this.text( this.countNum );
							}
						}
					);
				}, 800 * i
			);
		}
	);
	$( '.block-countup' ).each(
		function(i, that) {
			setTimeout(
				function() {
					$( that ).animate(
						{
							opacity: 1
						}, 500
					);
				}, 800 * i
			);
		}
	);
}

window.addEventListener( 'scroll', helpers.debounce( countUp, 500, false ) );
