/**
 * Blog functions.
 *
 * @package Embark
 * @since   1.0.0
 */

import $ from 'jquery';
import '../global/validate'; // @TODO: Figure out why ES6 import isnt working for jquery-validation.


function commentLabels() {
	$( '.comment-input' ).each(
		function () {
			var $this  = $( this );
			var field  = $this.find( '[type=text], [type=file], [type=email], [type=url], [type=password], textarea' );
			var span   = $( this ).find( '> label' );
			var onBlur = function () {
				if ($.trim( field.val() ) === '') {
					span.css( 'opacity', 1 );
					span.fadeIn( 100 );
				} else {
					span.fadeTo( 100, 0 );
				}
			};
			field.focus(
				function () {
					span.fadeOut( 100 );
				}
			).focusout(
				function() {
					onBlur();
				}
			);
			onBlur();
		}
	);
}


function validateSearch() {
	$( '.blog-search-form' ).validate(
		{

			onfocusout: false,

			rules: {
				s: {
					required: true,
					minlength: 2,
					normalizer: function(value) { return $.trim( value ); }
				},
			},

			messages: {
				s: "Please enter a search term",
			},

			errorElement: "div",
			errorPlacement: function(error, element) {
				element.after( error );
			}

		}
	);
}

function validateComments() {

	$.validator.methods.email = function( value, element ) {
		return this.optional( element ) || /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test( value );
	};

	$( '#commentform' ).validate(
		{

			onfocusout: false,

			rules: {
				author: {
					required: true,
					minlength: 2,
					normalizer: function(value) { return $.trim( value ); }
				},

				email: {
					required: true,
					email: true
				},

				comment: {
					required: true,
					minlength: 5,
					normalizer: function(value) { return $.trim( value ); }
				}
			},

			messages: {
				author: "Please enter in your name",
				email: "Please enter a valid email",
				comment: "Please enter a comment",
				url: "Please enter a valid URL (including http://)",
			},

			errorElement: "div",
			errorPlacement: function(error, element) {
				element.after( error );
			}

		}
	);
}

window.addEventListener( 'load', commentLabels );
window.addEventListener( 'load', validateSearch );
window.addEventListener( 'load', validateComments );
