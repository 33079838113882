/**
 * SVG `<use />` element polyfill
 *
 * @package Embark
 * @since   1.0.0
 */

/**
 * Imports svgxuse.
 *
 * A simple polyfill that fetches external SVGs referenced in <use>
 * elements when the browser itself fails to do so.
 *
 * @see https://www.npmjs.com/package/svgxuse
 *
 * @since 1.0.0
 */
import 'svgxuse';

/**
 * Imports jQuery.
 *
 * @see http://api.jquery.com/
 *
 * @since 1.0.0
 */
// Uncomment if you want to use the inlineSVGs() function.
// import $ from 'jquery';.
/**
 * Replace all svgs reference in img tags, with the actual svg.
 *
 * @since  1.0.0
 */
/*
function inlineSVGs() {
	const $svgImages = $('img[src$="svg"]');
	return $svgImages.each(function(i, item) {
		const _this = this;
		return $.get(this.src).success(function(data) {
			var $svg, a, nName, nValue, _attr, _i, _len;
			$svg = $(data).find('svg');
			_attr = _this.attributes;
			$.extend(_attr, $svg[0].attributes);
			for (_i = 0, _len = _attr.length; _i < _len; _i++) {
				a = _attr[_i];
				nName = a.nodeName;
				nValue = a.nodeValue;
				if (nName !== 'src' && nName !== 'style') {
					$svg.attr(nName, nValue);
				}
			}
			return $(_this).replaceWith($svg);
		});
	});
}
*/

// Uncomment if you want to use the inlineSVGs() function.
// window.addEventListener('load', inlineSVGs);.
