/**
 * Lazy-loading for images
 *
 * @package Embark
 * @since   1.0.0
 */

/**
 * Picturefill.
 *
 * A responsive image polyfill.
 *
 * @see https://www.npmjs.com/package/picturefill
 *
 * @since 1.0.0
 */
import 'picturefill';

/**
 * Vanilla Lazy Load.
 *
 * LazyLoad is a fast, lightweight and flexible script that speeds up
 * your web application by loading images only as they enter the viewport.
 * LazyLoad is written in plain (vanilla) Javascript, it supports responsive
 * images, it's SEO friendly and it has some other notable features.
 *
 * @see https://www.npmjs.com/package/vanilla-lazyload
 *
 * @since 1.0.0
 */
import LazyLoad from 'vanilla-lazyload';

/**
 * Object Fit Images.
 *
 * Polyfill object-fit/object-position on <img>: IE9, IE10, IE11, Edge, Safari, ...
 *
 * @see https://www.npmjs.com/package/object-fit-images
 *
 * @since 1.0.0
 */
import objectFitImages from 'object-fit-images';

/**
 * Kick-off lazy-loading, while reevaluating picturefill in the process.
 *
 * @since  1.0.0
 */
function initLazyLoad() {
	const lazyload = new LazyLoad(
		{
			elements_selector: 'img[data-original]',
			show_while_loading: true,
			callback_set: function(img) {
				picturefill(
					{
						reevaluate: true,
						elements: [img]
					}
				);
				objectFitImages( img );
			},
		}
	);
};

window.addEventListener( 'DOMContentLoaded', initLazyLoad );
