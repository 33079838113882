/**
 * Tabbed Content Toggling
 *
 * @package Embark
 * @since   1.0.0
 */

import $ from 'jquery';

function tabbedContent() {

	$( 'ul.tabs' ).each(
		function(){
			var $active, $content, $links = $( this ).find( 'a' );

			$active = $( $links.filter( '[href="' + location.hash + '"]' )[0] || $links[0] );
			$active.addClass( 'active' );
			$active.parent().addClass( 'active' );

			$content = $( $active[0].hash );

			$links.not( $active ).each(
				function () {
					$( this.hash ).hide();
				}
			);

			$( this ).on(
				'click', 'a', function(e){
					e.preventDefault();
					$active.removeClass( 'active' );
					$active.parent().removeClass( 'active' );
					$content.hide();

					$active  = $( this );
					$content = $( this.hash );

					$active.addClass( 'active' );
					$active.parent().addClass( 'active' );
					$content.show();
				}
			);
		}
	);

}

window.addEventListener( 'load', tabbedContent );
