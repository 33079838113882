/**
 * Countup block animation.
 *
 * @package Embark
 * @since   1.0.0
 */

import $ from 'jquery';

// Submit resource filter when a checkbox is clicked.
$( '.resource-filter' ).on(
	'change',function(){
		var actionString = $( '#resource-filter' ).attr( 'action' );
		actionString     = actionString.substring( 0, actionString.indexOf( '/' ) ); // remove existing page.
		$( '#resource-filter' ).attr( 'action', actionString + '/resources/overview/' ); // get out of product sub category page.
		$( '#resource-filter' ).submit();
	}
);

// Submit support doc filter when a checkbox is clicked.
$( '.support-filter' ).on(
	'change',function(){
		var actionString = $( '#support-filter' ).attr( 'action' );
		actionString     = actionString.substring( 0, actionString.indexOf( '/' ) ); // remove existing page.
		$( '#support-filter' ).attr( 'action', actionString + '/support/product-support-literature/' ); // get out of product sub category page.
		$( '#support-filter' ).submit();
	}
);

function scrollToResources() {
	// Scroll to results when filtered.
	if ($( '.layer--resource-library__list' ).hasClass( 'filtered' )) {
		$( 'html, body' ).animate(
			{
				scrollTop: $( '.layer--resource-library__list' ).offset().top
			}, 'slow'
		);
	}
}

function scrollToReps() {
	// Scroll to results when filtered.
	if ($( '.layer--locations__list' ).hasClass( 'filtered' )) {
		$( 'html, body' ).animate(
			{
				scrollTop: $( '.layer--locations__list' ).offset().top
			}, 'slow'
		);
	}
}

// Maintain filter on pagination of filtered results.
$( '.filtered > .pagination a' ).on(
	'click', function(e){
		console.log( 'triggered' );
		e.preventDefault();
		// numerical links.
		var link    = $( this ).text();
		var current = $( 'span.current' ).text();

		// check for prev/next links.
		if (link == "Previous") {
			link = parseInt( current ) - 1;
		} else if (link == "Next") {
			link = parseInt( current ) + 1;
		}

		if ( $( '#resource-filter' ).length ) {
			$( '#resource-filter #page' ).val( link );
			var actionString = $( '#resource-filter' ).attr( 'action' );
			actionString     = actionString.substring( 0, actionString.indexOf( '/' ) ); // remove existing page.
			$( '#resource-filter' ).attr( 'action', actionString + '/resources/overview/page/' + link );
			$( '#resource-filter' ).submit();
		}
		if ( $( '#support-filter' ).length ) {
				$( '#support-filter #page' ).val( link );
				var actionString2 = $( '#support-filter' ).attr( 'action' );
				actionString2     = actionString2.substring( 0, actionString2.indexOf( '/' ) ); // remove existing page.
				$( '#support-filter' ).attr( 'action', actionString2 + '/support/product-support-literature/page/' + link );
				$( '#support-filter' ).submit();
		}
	}
);

function zipValidate() {
	$( "#rep-locator" ).click(
		function(){
			$( ".error" ).hide();
			var hasError   = false;
			var searchVal  = $( "#zip" ).val();
			var isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test( searchVal );
			if ( ! isValidZip) {
				$( "#rep-locator" ).after( '<span class="error">Please enter a valid ZIP code.</span>' );
				hasError = true;
						$( "#zip" ).focus();
			}
			if (hasError == true) {
					return false;
			}
		}
	);
}

window.addEventListener( 'load', scrollToResources );
window.addEventListener( 'load', scrollToReps );
window.addEventListener( 'load', zipValidate );
