/**
 * Defines theme breakpoints
 *
 * @package Embark
 * @since   1.0.0
 */

/**
 * An object that contains a min and max screensize,
 * as well as methods to check if the window is in range
 * of those screensizes.
 *
 * @since 1.0.0
 *
 * @type {Class}
 */
export class Breakpoint {

	constructor( min, max ) {
		this.min = min;
		this.max = max;
	}

	aboveMin() {
		return this.getViewportWidth() >= this.min;
	}

	belowMax() {
		return this.getViewportWidth() <= this.max;
	}

	inRange() {
		return this.aboveMin() && this.belowMax();
	}
}

/**
 * A collection of Breakpoint objects for easy access in your project.
 * Best if these match the breakpoint values you setup in your CSS.
 *
 * @since 1.0.0
 *
 * @type {Class}
 */
export class Breakpoints {

	constructor( sizes ) {
		const defaults = [
			{
				key: 'xxs',
				min: 320
		},
			{
				key: 'xs',
				min: 480
		},
			{
				key: 'sm',
				min: 768
		},
			{
				key: 'md',
				min: 1025
		},
			{
				key: 'lg',
				min: 1200
		}
		];

		this.sizes       = sizes ? sizes : defaults;
		this.sizesLength = this.sizes.length;

		for (var i = 0; i < this.sizesLength; i++) {
			const key = this.sizes[i].key;
			const min = this.sizes[i].min;
			const max = this.sizes[i].max ? this.sizes[i].max : (this.sizes[i + 1] ? this.sizes[i + 1].min - 1 : Infinity);
			this[key] = new Breakpoint( min, max );
		}
	}
}
