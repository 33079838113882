/**
 * Leadership Card Toggle
 *
 * @package Embark
 * @since   1.0.0
 */

import $ from 'jquery';

import * as helpers from '../global/helpers';

function leadershipCardToggle() {
	const width = $( window ).width();

	if (width > 1024) {
		$( '.block-person' ).hover(
			function () {
				$( this ).addClass( 'open' );
				$( this ).find( '.block-person__content--lower' ).slideToggle( 250 );
			},
			function () {
				$( this ).removeClass( 'open' );
				$( this ).find( '.block-person__content--lower' ).slideToggle( 250 );
			}
		)
	}
}


window.addEventListener( 'load', leadershipCardToggle );

const mainwidth = $( window ).width();

if (mainwidth > 580) {
	window.addEventListener( 'resize', helpers.debounce( leadershipCardToggle, 100, false ) );
}
