/**
 * Homepage Masthead
 *
 * @package Embark
 * @since   1.0.0
 */

import $ from 'jquery';
import 'jquery-ui';
import 'jquery-color';

import * as helpers from '../global/helpers';

function hpMasthead() {

	$( '.hp-tabs li' ).hover(
		function() {
			$( '.masthead--homepage .screen' ).css( 'background-color', 'rgba(0, 0, 0, 0.5)' );
			$( '.masthead__body' ).css( 'background-color', 'transparent' );
		},
		function() {
			$( '.masthead--homepage .screen' ).css( 'background-color', 'transparent' );
			$( '.masthead__body' ).css( 'background-color', 'rgba(0, 0, 0, 0.5)' );
		}
	);
}

function mastheadSizing() {
	const width = $( window ).width();

	if ( width < 768 ) {
		var mhHeight      = $( '.masthead__body' ).height();
		var mhFinalHeight = mhHeight + 100;
		if ( ! $( '.masthead' ).hasClass( '.masthead--homepage' ) ) {
			$( '.masthead .inner' ).css( 'min-height', mhFinalHeight + 'px' );
		}
	}
}

window.addEventListener( 'load', hpMasthead );
window.addEventListener( 'load', mastheadSizing );
window.addEventListener( 'resize', helpers.debounce( mastheadSizing, 100, false ) );
